<div class="d-flex flex-row align-center px-2 pb-2">
  <div class="d-flex flex-column mr-auto">
    <app-back-button *ngIf="hasBackButton"> </app-back-button>
    <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    <h1 class="mb-0 fw-bold">
      {{ title }}
      <ng-content></ng-content>
    </h1>
  </div>

  <ng-container *ngIf="actionButton">
    <div class="text-right xs-hide lg-show">
      <!--  xs="4" hide="xs" show="lg"  -->
      <app-action-button
        type="cta"
        [icon]="actionButton.icon"
        [label]="actionButton.label"
        [url]="actionButton.url"
        (click)="actionButton.onClick()"
      ></app-action-button>
    </div>
    <div class="xs-show lg-hide text-right">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <app-action-button
          type="menu"
          [icon]="actionButton.icon"
          [label]="actionButton.label"
          [url]="actionButton.url"
          (click)="actionButton.onClick()"
        ></app-action-button>
      </mat-menu>
    </div>
  </ng-container>

  <ng-container *ngIf="actionButtons">
    <div class="text-right xs-hide lg-show">
      <button
        mat-raised-button
        color="primary"
        [matMenuTriggerFor]="menu"
        #menuTrigger="matMenuTrigger"
      >
        <div class="d-flex align-center">
          Aktionen
          <mat-icon class="ml-1">{{
            menuTrigger.menuOpen ? 'expand_less' : 'expand_more'
          }}</mat-icon>
        </div>
      </button>
    </div>
    <div class="xs-show lg-hide text-right">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
    <mat-menu #menu="matMenu" xPosition="before">
      <app-action-button
        *ngFor="let actionButton of actionButtons"
        type="menu"
        [icon]="actionButton.icon"
        [label]="actionButton.label"
        [url]="actionButton.url"
        (click)="actionButton.onClick()"
      ></app-action-button>
    </mat-menu>
  </ng-container>
</div>
